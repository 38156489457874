<template>
    <div>
        <Header/>
        <div class="grace-body">
            <div class="grace-header-main"></div>
            <div class="grace-page-body">
                <div class="grace-form-item grace-border-b">
                    <div class="grace-form-label">
                        <span>{{former_password}}</span>
                    </div>
                        <div class="grace-form-body">
                        <input type="password" v-model="oldpassword" class="grace-form-input_s" :placeholder="please_enter_former_password">
                    </div>
                </div>
                <div class="grace-form-item grace-border-b">
                    <div class="grace-form-label">
                        <span>{{new_password}}</span>
                    </div>
                        <div class="grace-form-body">
                        <input type="password" class="grace-form-input_s"  v-model="newpassword" style="direction: rtl;" :placeholder="please_enter_new_password">
                    </div>
                </div>
                <div style="margin-top:30px;">
                    <button class="grace-button" type="primary" @click="update_password">{{update_passwords}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../Header";
import {apiUsersResetPassword} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        oldpassword:'',
        newpassword:'',
        new_password:'',
        former_password:'',
        please_enter_former_password:'',
        please_enter_new_password:'',
        update_passwords:'',
        lang_id:'',
    }
  },
  created(){
      let lang_id = this.$store.state.lang_id;
      this.lang_id = lang_id;
      if(lang_id == 1){
          this.new_password = this.$zh_ft('new_password');
          this.former_password = this.$zh_ft('former_password');
          this.please_enter_former_password = this.$zh_ft('please_enter_former_password');
          this.please_enter_new_password = this.$zh_ft('please_enter_new_password');
          this.update_passwords = this.$zh_ft('update_password');
      }else{
          this.new_password = this.$zh_jt('new_password');
          this.former_password = this.$zh_jt('former_password');
          this.please_enter_former_password = this.$zh_jt('please_enter_former_password');
          this.please_enter_new_password = this.$zh_jt('please_enter_new_password');
          this.update_passwords = this.$zh_jt('update_password');
      }
  },
  methods:{
      update_password(){
        let oldpassword = this.oldpassword;
        let newpassword = this.newpassword;
        let lang_id = this.lang_id;
        if(!oldpassword){
        if(lang_id == 1){
            this.$message.error('請輸入舊密碼');
        }else{
            this.$message.error('请输入旧密码');
        }
        return false;
        }

        if(oldpassword.length < 6){
        if(lang_id == 1){
            this.$message.error('舊密碼位數最少6位');
        }else{
            this.$message.error('旧密码位数最少6位');
        }
        return false;
        }

        if(!newpassword){
            if(lang_id == 1){
                this.$message.error('請輸入新密碼');
            }else{
                this.$message.error('请输入新密碼');
            }
        return false;
        }
        if(newpassword.length < 6){
            if(lang_id == 1){
                this.$message.error('新密碼位數最少6位');
            }else{
                this.$message.error('新密码位数最少6位');
            }
        return false;
        }

        apiUsersResetPassword({
            old_password:this.$md5(oldpassword),
            new_password:this.$md5(newpassword)
        }).then(res=>{
            if(res.code == 200){
                this.$message.success('修改成功');
                location.href="../";
            }else{
                this.$message.error(res.message);
            }
        }).catch(error=>{
            console.log(error);
        });
      }
  }
}
</script>
<style>

.grace-button{
    border:none;
    width:100%;
    font-size: 14px;
    line-height: 42px;
    padding: 0;
    border-radius: 2px;
    color: #fff;
    background: #C49B6C !important;
}
.grace-form-input_s{
    width: 100%;
    height: 20px;
    outline: none;
    line-height: 20px;
    margin: 10px 0 0 -5px;
    background: none;
    border: 0;
    text-align: right;
    color: #333;
    font-size: 13px;
}
.grace-form-body{
    width: 350px;
    margin-left: 10px;
    overflow: hidden;
}
.grace-form-label{
    text-align: left;
    width: 84px;
    display: block;
    width: 90px;
    height: 50px;
    font-size: 14px;
    line-height: 50px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    overflow: hidden;
    color: #000000;
}
.grace-border-b{
    border-bottom: 1px solid #f8f8f8;
}
.grace-form-item{
    padding: 5px 0;
    align-items: center;
    display: -webkit-flex;
}
.grace-page-body{
    padding: 50px 0 0px 0;
}
.grace-header-main{
    width: 144px;
    min-height: 44px;
}
  .grace-body{
    padding: 20px;
    width: unset;
    height: 90vh;
    background: #fff;
    margin-top: -1rem;
}
</style>